import { render, staticRenderFns } from "./Homework.vue?vue&type=template&id=0e8a2857&scoped=true&"
import script from "./Homework.vue?vue&type=script&lang=js&"
export * from "./Homework.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e8a2857",
  null
  
)

export default component.exports