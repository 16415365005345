<template>
    <div>WorkVideo</div>
</template>

<script>
    export default {
        name: 'WorkVideo',
    };
</script>

<style scoped>

</style>
