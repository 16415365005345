<template>
    <div>
        <h2>Home</h2>
        <p>
            <router-link to="/home/class-schedules/1/homework">课堂作业</router-link>
        </p>
        <p>
            <router-link to="/home/class-schedules/1">课次详情</router-link>
        </p>
        <p>
            <router-link to="/home/class-schedules/1/works/video">作品演示</router-link>
        </p>
        <p>
            <router-link to="/home/class-schedules/1/works/play">运行作品</router-link>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'Home',
    };
</script>

<style scoped>

</style>
