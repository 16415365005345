import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './views/Home.vue';
import ClassSchedule from './views/ClassSchedule.vue';
import Homework from './views/Homework.vue';
import WorkVideo from './views/WorkVideo.vue';
import WorkPlay from './views/WorkPlay.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            title: '米德云课堂',
            description: '关联学生的班级及已上课次列表',
        },
    },
    {
        path: '/home/class-schedules/:classSchedulesUuid',
        name: 'ClassSchedule',
        component: ClassSchedule,
        meta: {
            title: '课次详情',
            description: '教学目标、重点难、编程作品等',
        },
    },
    {
        path: '/home/class-schedules/:classSchedulesUuid/homework',
        name: 'Homework',
        component: Homework,
        meta: {
            title: '课堂作业',
            description: '做作业及查看作业',
        },
    },
    {
        path: '/home/class-schedules/:classSchedulesUuid/works/video',
        name: 'WorkVideo',
        component: WorkVideo,
        meta: {
            title: '作品演示',
            description: '作品演示视频、作品介绍、操作说明',
        },
    },
    {
        path: '/home/class-schedules/:classSchedulesUuid/works/play',
        name: 'WorkPlay',
        component: WorkPlay,
        meta: {
            title: '运行作品',
            description: '米德启萌编程（JR）和图形化编程（Fulcrum）运行入口、作品介绍、操作说明',
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title;
    next();
});

export default router;
