<template>
    <div>ClassSchedule</div>
</template>

<script>
    export default {
        name: 'ClassSchedule',
    };
</script>

<style scoped>

</style>
